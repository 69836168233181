<template>
	<div>
		<div class="regulation-box" v-if="shData != null && shData.records.length >0">
		<div class="regulationBoxOne">
		    <div class="regulationBox" @click="goGdfhDetail(item.webUrl)" v-for="(item,index) in shData.records"
		         :key="index">
		        <div class="regulationPar">
		            <div>
		                <div class="blueQiu"></div>
		                <p class="parTitle">{{item.shfhName}}</p>
		            </div>
		            <p class="parTime">{{item.fhDate}}</p>
		        </div>
		    </div>
		</div>
		<el-pagination style="text-align: right; margin: 60px 0;"
		               background
		               prev-text="上一页"
		               next-text="下一页"
		               layout="prev, pager, next"
		               @current-change="careChange"
		               :page-size=10
		               :total="shData.total">
		</el-pagination>	
		</div>
		<div id="nodata" v-else>
			没有搜索结果
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			shData:{
				type:Object,
				default:null
			},
			page:{
				type:Number,
				default:1
			}
		},
		data() {
			return{
				
			}
		},
		methods:{
			// 跳转至资讯详情页
			goGdfhDetail(path) {
			    window.open(path)
			},
			careChange(page){
				this.$emit('currentsh',page)
			}
		}
	}
</script>

<style>
	@import '~@/assets/css/regulation.css';
</style>
