<template>
	<div>
		<div class="titleTap-box" v-if="zxData != null && zxData.records.length >0">
		    <div class="care" @click="informationPar(item.webUrl)" v-for="(item,index) in zxData.records" :key="index">
		        <img :src="item.titleImage">
		        <div class="care-content">
		            <h5>{{item.newsTitle}}</h5>
		            <p>{{item.subContent}}</p>
		        </div>
		        <div class="care-time">
		            <h3>{{item.year}}</h3>
		            <p>{{item.monthAndDay}}</p>
		        </div>
		    </div>
		    <el-pagination v-if="zxData.total>0" style="text-align: right; margin:60px 0;"
		                   background
		                   prev-text="上一页"
		                   next-text="下一页"
		                   layout="prev, pager, next"
		                   :current-page="page"
		                   @current-change="currentChange"
		                   :page-size=4
		                   :total="zxData.total">
		    </el-pagination>
		</div>
		<div class="nodata" v-else>没有搜索结果</div>
	</div>
</template>

<script>
	export default {
		props:{
		
			zxData:{
				type:Object,
				default:null
			},
			page:{
				type:Number,
				default:1
			}
		},
		data() {
			return{
				
			}
		},
		methods:{
			// 跳转至资讯详情页
			informationPar(path) {
			    this.$router.push(path)
			},
			currentChange(page){
				this.$emit('currentzx',page)
			}
		}
	}
</script>

<style>
	@import '~@/assets/css/points.css';
</style>
