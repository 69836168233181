<template>
	<div>
		<div class="member-box" v-if="memgerList != null && memgerList.records.length >0">
			<div class="member-list">
			<div class="member-item" v-for="item,index in memgerList.records" :key="index" @click="goMemberDetail(item.webUrl)">
				<div class="item-img">
					<img :src="item.fcImage" >
				</div>
				<div class="item-info">
					<p class="item-info-name">{{item.fcName}}</p>
					<p class="item-info-title" :title="item.remark">{{item.remark}}</p>
				</div>
			</div>
		</div>
		<el-pagination v-if="memgerList.total>0" style="text-align: right; margin: 60px 0;"
		               background
		               prev-text="上一页"
		               next-text="下一页"
		               layout="prev, pager, next"
		               :current-page="page"
		               @current-change="handleCurrentChange"
		               :page-size="10"
		               :total="memgerList.total">
		</el-pagination>
		</div>
		<div class="nodata" v-else>没有搜索结果</div>
	</div>
</template>

<script>
	export default {
		props:{
			memgerList:{
				type:Object,
				default:null
			},
			page:{
				type:Number,
				default:1
			}
		},
		data() {
			return{
				
			}
		},
		methods:{
			// 跳转至资讯详情页
			goMemberDetail(path) {
			    this.$router.push(path)
			},
			handleCurrentChange(page){
				this.$emit('currenthy',page)
			}
		}
	}
</script>

<style>
	 @import '~@/assets/css/member.css';
</style>
