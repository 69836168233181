<template>
	<div class="search-page">
		<div class="title-bread">
			<p>当前位置：</p>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/search' }">搜索</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="search-input-top">
			<el-input suffix-icon="el-icon-search" v-model.trim="searchkey" placeholder="请输入内容" @change="getSearch(1)">
			</el-input>
		</div>
		<div class="search-tab">
			<p v-for="item in tab" :key="item.id" :class="active == item.id ? 'searchActive': ''"
				@click="changetab(item.id,item.name)">{{item.name}}</p>
		</div>
		<!-- 资讯页搜索结果 -->
		<div class="search-zx" v-if="active == 0">
			<zx :zxData="zxData" @currentzx="currentzxpage" :page="parseInt(page) "></zx>
		</div>
		<!-- 会员结果页 -->
		<div class="search-hy" v-if="active == 1">
			<hy :memgerList="hyData" @currenthy="currenthypage" :page="parseInt(page)"></hy>
		</div>
		<!-- 商会结果页 -->
		<div class="search-sh" v-if="active == 2">
			<sh :shData="shData" @currentsh="currentshpage" :page="parseInt(page)"></sh>
		</div>
	</div>
</template>

<script>
	import zx from './common/zhixun.vue'
	import hy from './common/huiyuan.vue'
	import sh from './common/shanghui.vue'
	export default {
		components: {
			zx,
			hy,
			sh
		},
		watch: {
			$route: {
				immediate: true,
				handler(to) {
					if (to.path == '/search') {
						if (this.$route.query.key) {
							// 搜索关键词
							this.searchkey = this.$route.query.key
						} else {
							this.searchkey = ""
						}
						// 有类别的情况下
						if (this.$route.query.type) {
							// 类别
							this.searchtype = this.$route.query.type
						} else {
							this.searchtype = "资讯"
						}


						// 有页码的情况下,用页码去请求数据
						if (this.$route.query.page) {
							
							this.page = this.$route.query.page
						} else {
							this.page = 1
							// 根据类别和关键词请求数据
							
						}
						this.handletype(this.searchtype)
					}
				}

			}
		},
		data() {
			return {
				searchkey: '', // 关键词
				searchtype: '', // 类别
				tab: [{
					id: 0,
					name: '资讯'
				}, {
					id: 1,
					name: '会员'
				}, {
					id: 2,
					name: '商会'
				}],
				active: null, // 激活的索引值
				zxData: null, // 资讯的数据
				hyData: null, // 会员数据
				shData: null, // 商会数据
				page: 1, // 默认第一页面

			}
		},
		methods: {

			handletype(type) {
				// this.page = 1
				if (type == '资讯') {
					this.active = 0
					this.setZX()
				} else if (type == '会员') {
					this.active = 1
					this.setHY()
				} else if (type == '商会') {
					this.active = 2
					this.setSH()
				}

				// console.log(2)
			},
			getSearch(page) {
				this.$router.push({
					path: '/search',
					query: {
						key: this.searchkey,
						type: this.searchtype,
						page: page
					}
				})
			},
			// 获取资讯数据
			setZX() {
				this.$http.searchZX({
					title: this.searchkey,
					page: this.page
				}).then((res) => {
					this.zxData = res.data
					// console.log(this.zxData)
				})
			},
			// 获取会员数据
			setHY() {
				this.$http.searchHY({
					title: this.searchkey,
					page: this.page
				}).then((res) => {
					this.hyData = res.data
					// console.log(this.hyData)
				})
			},
			// 获取商会数据
			setSH() {
				this.$http.searchSH({
					title: this.searchkey,
					page: this.page
				}).then((res) => {
					this.shData = res.data
					// console.log(this.shData)
				})
			},
			// 点击改变页数
			currentzxpage(page) {
				// var p = parseInt(page)
				this.getSearch(page)
				// this.setZX()
			},
			currenthypage(page) {
				this.getSearch(page)
				// this.setHY()
			},
			currentshpage(page) {
				this.getSearch(page)
				// this.setHY()
			},
			changetab(id, type) {
				this.active = id
				this.searchtype = type
				this.getSearch(1)


				// console.log(this.active)
			},

		}
	}
</script>

<style scoped="scoped">
	.search-page {
		width: 1200px;
		margin: 0 auto;
		min-height: 500px;
	}

	.title-bread {
		display: flex;
		align-items: center;
		height: 60px;
		font-size: 18px;
		border-bottom: 1px solid #f2f2f2;
		margin-bottom: 60px;
	}

	.el-breadcrumb {
		font-size: 18px;
	}

	/deep/.el-breadcrumb__inner.is-link {
		font-weight: normal;
		color: #000000;
		cursor: pointer;
	}

	.search-tab {
		display: flex;
		padding: 20px 0;
		margin-bottom: 50px;
	}

	.search-tab p {
		width: 100px;
		text-align: center;
		margin-right: 20px;
		font-size: 16px;
		cursor: pointer;
	}

	.searchActive {
		position: relative;
	}

	.searchActive::after {
		content: "";
		position: absolute;
		width: 50px;
		height: 4px;
		border-radius: 4px;
		background: #005bac;
		bottom: -10px;
		left: 50%;
		margin-left: -25px;
	}

	.search-input-top {
		margin-bottom: 50px;
	}

	/deep/.search-input-top .el-input__inner {
		width: 1200px;
		height: 70px;
		background-color: #f2f2f2;
		border-radius: 35px;
		font-size: 20px;
		color: #000000;
		padding-left: 59px;
	}

	/deep/.search-input-top .el-input__icon {
		font-size: 20px;
		width: 58px;
		color: #000000;
	}
</style>
